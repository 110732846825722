@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body {
  font-family: 'Poppins', sans-serif;
}

option {
  color: black;
}

.no-data-card {
  min-height: 40vh;
}
.retry-btn {
  margin-top: 0.5rem;
}
.full-width {
  width: 100%;
}

.mulch-react-select {
  margin-right: 20px;
  height: var(--chakra-sizes-12);
}

.mulch-react-select .mulch-react-select__control {
  height: 100%;
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--chakra-colors-secondaryGray-100);
}

.mulch-react-select
  .mulch-react-select__control
  .mulch-react-select__value-container {
  width: 60px;
}

.mulch-react-select .mulch-react-select__menu {
  font-size: 12px;
}

.mulch-react-select
  .mulch-react-select__control
  .mulch-react-select__single-value {
  font-size: 20px;
}
.space-between-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-head-box {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-border);
}
.page-main-box {
  padding-top: 10px;
}
.start-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
#refresh-button {
  background-color: transparent;
  outline: none;
  border: none;
}
#refresh-button:active {
  background-color: transparent;
  transform: rotate(360deg);
  animation-name: spin;
  animation-duration: 400ms;
  animation-iteration-count: 7;
  animation-timing-function: ease;
  outline: none;
  border: none;
}
.success-text {
  color: var(--primary-success);
}
.danger-text {
  color: var(--primary-danger);
}
.capitalize-text {
  text-transform: capitalize;
}
