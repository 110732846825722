.preview-box {
  border: 1px solid var(--primary-border);
  border-collapse: collapse;
  margin-bottom: 20px;
}

.preview-box .preview-heading {
  background-color: var(--primary-light);
  padding: 10px;
}
.preview-box table {
  width: 100%;
  border-collapse: collapse;
}
.preview-box table tr {
  border: 1px solid var(--primary-border);
}
.preview-box table td {
  padding: 10px;
}

.success-status {
  color: var(--primary-success);
}
.rejected-status {
  color: var(--primary-danger);
}

.preview-box .status {
  color: var(--primary-color);
}
