.table-container {
  .table-title-header {
    font-weight: bolder;
    font-size: larger;
    display: flex;
    align-items: center;
  }

  table {
    border-collapse: collapse;
    th,
    tr,
    th {
      svg {
        display: inline-flex;
        align-items: center;
      }
      .current-order {
        color: green;
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .table-actions {
        display: flex;
        gap: 2%;
        .action-button {
          outline: none;
          border: none;
          padding: 0px 2px;
        }
      }
    }

    thead tr {
      border-bottom: 1px solid;
    }
  }

  & .pagination {
    display: flex;
    justify-content: flex-end;
    .active{
     .page-link{
      z-index: 0;
     }
    }
  }

  & .dropdown-toggle {
    margin: 1rem;
    background-color: #0d6efd;
  }

  & .table-search-bar {
    width: 400px;
    margin-bottom: 10px;
  }
}
