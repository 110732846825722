.realtor-portfolio-page {
  margin-top: 2%;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #ffff;
    border-radius: 5px;
    align-items: center;
    width: 98%;
    padding: 5px;

    .search-bar {
      .market-place-search {
        width: 100%;
      }
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  .view-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1%;
    padding: 5px;
    width: 98%;

    .title-card {
      display: flex;
      align-items: center;
      padding: 5px;
      gap: 1rem;

      .title-icon {
        font-size: 2rem;
      }
    }
  }
}
